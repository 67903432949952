import HackleCore from "../../HackleCore"
import Logger from "../logger"
import { HackleEvent } from "../model/model"
import { UserManager } from "../user/UserManager"
import { Page } from "./Page"
import { PageListener } from "./PageListener"

const log = Logger.log

export class PageEventTracker implements PageListener {
  static readonly PAGE_VIEW_EVENT_KEY = "$page_view"
  static readonly PAGE_NAME_PROPERTY_KEY = "$page_name"

  constructor(private readonly core: HackleCore, private readonly userManager: UserManager) {}

  onPageStarted(page: Page, timestamp: number): void {
    this.track(page, timestamp)
  }

  onPageEnded(_: Page, ___: number): void {}

  track(page: Page, timestamp?: number) {
    log.debug("tracking page view")

    const hackleEvent: HackleEvent = {
      key: PageEventTracker.PAGE_VIEW_EVENT_KEY,
      properties: {
        [PageEventTracker.PAGE_NAME_PROPERTY_KEY]: page.pageTitle
      }
    }

    this.core.track(hackleEvent, this.userManager.resolve(), timestamp)
  }
}
