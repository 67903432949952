import UAParser from "./UAParser"
import { Properties } from "../../core/internal/model/model"

export default class HacklePropertyGenerator {
  static generate(global?: any): Properties {
    let win
    // Server-Side Ignore
    if (typeof global === "undefined") {
      win = {
        navigator: { userAgent: "", vendor: "", languages: [] },
        screen: {
          width: "",
          height: ""
        }
      }
    } else {
      win = global
    }

    const result = UAParser.parse(win.navigator.userAgent)

    const browser = result.browser
    const os = result.os
    const device = result.device
    const screen = win.screen
    const locale = win.navigator.languages?.[0] || ""
    const language = locale.split("-")[0]
    const deviceType = (device.type ?? "pc").replace(/\b(mobile|smarttv)\b/g, (match, p1) => {
      if (p1 === "mobile") return "phone"
      if (p1 === "smarttv") return "tv"
      return match
    })
    let osName = os.name || ""
    let orientation = ""
    let timezone = ""
    try {
      osName = osName.match(/\b(Mac|Windows|Linux|Android|iOS)\b/)?.[0] ?? osName
      orientation = screen.orientation.type.match(/\b(landscape|portrait)\b/)?.[0] ?? ""
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || ""
    } catch (e) {}

    return {
      platform: "Web",
      browserName: browser.name || "",
      browserMajorVersion: browser.major || "",
      browserVersion: browser.version || "",
      osName: osName,
      osVersion: os.version || "",
      deviceModel: device.model || "",
      deviceType: deviceType,
      deviceVendor: device.vendor || "", // @Deprecated. Use deviceBrand instead
      deviceBrand: device.vendor || "",
      locale: locale,
      language: language,
      orientation: orientation,
      userAgent: win.navigator.userAgent || "",
      screenWidth: screen.width || "",
      screenHeight: screen.height || "",
      timeZone: timezone
    }
  }
}
