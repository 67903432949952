import { Synchronizer } from "./Synchronizer"
import { ScheduledJob, Scheduler } from "../scheduler/Scheduler"
import Logger from "../logger"
import { CompositeSynchronizer } from "./CompositeSynchronizer"
import { NO_POLLING } from "../../../config"

export class PollingSynchronizer implements Synchronizer {
  private pollingJob: ScheduledJob | null

  constructor(
    private readonly delegate: CompositeSynchronizer,
    private readonly scheduler: Scheduler,
    private readonly intervalMillis: number
  ) {
    this.pollingJob = null
  }

  async sync(): Promise<void> {
    try {
      await this.delegate.sync()
    } catch (e) {
      Logger.log.error(`Failed to sync: ${e}`)
    }
  }

  start() {
    if (this.intervalMillis === NO_POLLING) {
      return
    }

    if (this.pollingJob !== null) {
      return
    }

    this.pollingJob = this.scheduler.schedulePeriodically(this.intervalMillis, this.intervalMillis, () => this.sync())
  }

  stop() {
    if (this.intervalMillis === NO_POLLING) {
      return
    }

    this.pollingJob?.cancel()
    this.pollingJob = null
  }

  async close(): Promise<void> {
    this.stop()
    await this.delegate.close()
  }
}
