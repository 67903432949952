import { PropertyOperations } from "../../../hackle/property/PropertyOperations"
import { HackleUser, User } from "../model/model"
import { UserListener } from "./UserListener"
import { Synchronizer } from "../sync/Synchronizer"
import { Updated } from "../util/Updated"

export interface UserManager extends Synchronizer {
  readonly currentUser: User

  addListener(listener: UserListener): void

  resolve(user?: User | string): HackleUser

  toHackleUser(user: User): HackleUser

  setUser(user: User): Updated<User>

  setUserId(userId: string | undefined): Updated<User>

  setDeviceId(deviceId: string): Updated<User>

  updateUserProperties(operations: PropertyOperations): Updated<User>

  resetUser(): Updated<User>

  syncIfNeeded(updated: Updated<User>): Promise<void>
}

export const isSameUser = (a: User | null, b: User): boolean => {
  if (a === null) return false
  return a.userId === b.userId && a.deviceId === b.deviceId
}

export const mergeUsers = (oldUser: User, newUser: User): User => {
  if (isSameUser(oldUser, newUser)) {
    return {
      ...newUser,
      properties: {
        ...oldUser.properties,
        ...newUser.properties
      }
    }
  }
  return newUser
}
