// import {VariationKey} from "../VariationKey"

import Logger from "../logger"
import ObjectUtil from "../util/ObjectUtil"
import IdentifierUtil from "../util/IdentifierUtil"
import PropertyUtil from "../util/PropertyUtil"
import {
  InAppMessageAlignmentDto,
  InAppMessageContextActionDto,
  InAppMessageContextButtonDto,
  InAppMessageContextCloseButtonDto,
  InAppMessageContextDto,
  InAppMessageContextImageDto,
  InAppMessageContextMessageDto,
  InAppMessageContextTextContentDto,
  InAppMessageContextTextDto,
  InAppMessageDto,
  InAppMessageDurationCapDto,
  InAppMessageEventFrequencyCapDto,
  InAppMessageEventTriggerRuleDto,
  InAppMessageIdentifierCapDto,
  InAppMessageLayoutDto,
  InAppMessagePositionalButtonDto,
  InAppMessageTargetContextDto,
  InAppMessageTargetRuleOverrideDto
} from "../workspace/iam"
import {
  BucketDto,
  ContainerDto,
  ContainerGroupDto,
  EventTypeDto,
  ExecutionTargetUrlsDto,
  ExperimentDto,
  ParameterConfigurationDto,
  RemoteConfigParameterDto,
  RemoteConfigParameterValueDto,
  RemoteConfigTargetRuleDto,
  SegmentDto,
  SplitUrlConfigDto,
  TargetActionDto,
  TargetConditionDto,
  TargetDto,
  TargetKeyDto,
  TargetMatchDto,
  TargetRuleDto
} from "../workspace/dto"
import CollectionUtil from "../util/CollectionUtil"
import { PropertyOperation } from "../../../hackle/property/PropertyOperation"
import { TIME_UNIT, TimeUtil } from "../util/TimeUtil"
import { DefaultParameterConfig, EmptyParameterConfig, ParameterConfig } from "../config/ParameterConfig"

export type Int = number
export type Long = number
export type List<T> = T[]
export type Double = number
export type UserId = string
export type VariationId = Long
export type VariationKey = string
export type ExperimentId = Long
export type ExperimentKey = Long
export type BucketId = Long
export type EventId = Long
export type EventKey = string
export type SegmentKey = string
export type ContainerId = Long
export type ParameterConfigurationId = Long
export type RemoteConfigParameterKey = string
export type InAppMessageKey = Long

const log = Logger.log

function parseOrNull<T extends string>(types: readonly T[], type: string): T | undefined {
  const t = types.find((it) => it === type || it.toLowerCase() === type.toLowerCase())
  if (!t) {
    log.debug(`Unsupported type [${type}]. Please use the latest version of sdk.`)
  }
  return t
}

function parseAllOrNull<T extends string>(types: readonly T[], names: string[]): T[] | undefined {
  if (ObjectUtil.isNullOrUndefined(names)) {
    return undefined
  }
  const result = new Array<T>()
  for (let name of names) {
    const element = parseOrNull(types, name)
    if (ObjectUtil.isNullOrUndefined(element)) {
      return undefined
    }
    result.push(element)
  }
  return result
}

/**
 * An object that contains the decided config parameter value and the reason for the config parameter decision.
 */
export class RemoteConfigDecision {
  value: string | number | boolean
  reason: DecisionReason

  constructor(value: string | number | boolean, reason: DecisionReason) {
    this.value = value
    this.reason = reason
  }

  static of(value: string | number | boolean, reason: DecisionReason): RemoteConfigDecision {
    return new RemoteConfigDecision(value, reason)
  }
}

export class InAppMessageDecision {
  private constructor(
    readonly inAppMessage: InAppMessage | undefined,
    readonly message: InAppMessageContextMessage | undefined,
    readonly reason: DecisionReason,
    readonly properties: Properties
  ) {}

  isShow(): boolean {
    return ObjectUtil.isNotNullOrUndefined(this.inAppMessage) && ObjectUtil.isNotNullOrUndefined(this.message)
  }

  static of(
    reason: DecisionReason,
    inAppMessage?: InAppMessage,
    message?: InAppMessageContextMessage,
    properties: Properties = {}
  ): InAppMessageDecision {
    return new InAppMessageDecision(inAppMessage, message, reason, properties)
  }
}

/**
 * An object that contains the decided variation and the reason for the decision.
 */
export class Decision implements ParameterConfig {
  variation: VariationKey
  reason: DecisionReason
  config: ParameterConfig
  experiment: HackleExperiment | undefined

  constructor(
    variation: VariationKey,
    reason: DecisionReason,
    config: ParameterConfig,
    experiment: HackleExperiment | undefined
  ) {
    this.variation = variation
    this.reason = reason
    this.config = config
    this.experiment = experiment
  }

  static of(
    variation: VariationKey,
    reason: DecisionReason,
    config: ParameterConfig = new EmptyParameterConfig(),
    experiment: HackleExperiment | undefined = undefined
  ) {
    return new Decision(variation, reason, config, experiment)
  }

  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    switch (typeof defaultValue) {
      case "string":
        return this.config.get(key, defaultValue)
      case "number":
        return this.config.get(key, defaultValue)
      case "boolean":
        return this.config.get(key, defaultValue)
      default:
        return this.config.get(key, defaultValue)
    }
  }
}

/**
 * An object that contains the decided flag and the reason for the feature flag decision.
 */
export class FeatureFlagDecision implements ParameterConfig {
  isOn: boolean
  reason: DecisionReason
  config: ParameterConfig
  experiment: HackleExperiment | undefined

  constructor(
    isOn: boolean,
    reason: DecisionReason,
    config: ParameterConfig,
    experiment: HackleExperiment | undefined
  ) {
    this.isOn = isOn
    this.reason = reason
    this.config = config
    this.experiment = experiment
  }

  static on(
    reason: DecisionReason,
    config: ParameterConfig = new EmptyParameterConfig(),
    experiment: HackleExperiment | undefined = undefined
  ): FeatureFlagDecision {
    return new FeatureFlagDecision(true, reason, config, experiment)
  }

  static off(
    reason: DecisionReason,
    config: ParameterConfig = new EmptyParameterConfig(),
    experiment: HackleExperiment | undefined = undefined
  ): FeatureFlagDecision {
    return new FeatureFlagDecision(false, reason, config, experiment)
  }

  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    switch (typeof defaultValue) {
      case "string":
        return this.config.get(key, defaultValue)
      case "number":
        return this.config.get(key, defaultValue)
      case "boolean":
        return this.config.get(key, defaultValue)
      default:
        return this.config.get(key, defaultValue)
    }
  }
}

export interface Config {
  get(key: string, defaultValue: string): string

  get(key: string, defaultValue: number): number

  get(key: string, defaultValue: boolean): boolean
}

/**
 * An interface model for remote Config
 */
export interface HackleRemoteConfig extends Config {}

export class EmptyHackleRemoteConfig implements HackleRemoteConfig {
  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    return defaultValue
  }
}

export class ParameterConfiguration implements ParameterConfig {
  constructor(public id: Long, private readonly config: ParameterConfig) {}

  public static fromJson(dto: ParameterConfigurationDto): ParameterConfiguration {
    return new ParameterConfiguration(
      dto.id,
      new DefaultParameterConfig(CollectionUtil.associate(dto.parameters, (it) => [it.key, it.value]))
    )
  }

  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    switch (typeof defaultValue) {
      case "string":
        return this.config.get(key, defaultValue)
      case "number":
        return this.config.get(key, defaultValue)
      case "boolean":
        return this.config.get(key, defaultValue)
      default:
        return defaultValue
    }
  }
}

/**
 * Describes the reason for the [Variation] decision.
 */
export class DecisionReason {
  static SDK_NOT_READY = "SDK_NOT_READY"
  static EXCEPTION = "EXCEPTION"
  static INVALID_INPUT = "INVALID_INPUT"
  static UNSUPPORTED_PLATFORM = "UNSUPPORTED_PLATFORM"
  static TYPE_MISMATCH = "TYPE_MISMATCH"

  static EXPERIMENT_NOT_FOUND = "EXPERIMENT_NOT_FOUND"
  static EXPERIMENT_DRAFT = "EXPERIMENT_DRAFT"
  static EXPERIMENT_PAUSED = "EXPERIMENT_PAUSED"
  static EXPERIMENT_COMPLETED = "EXPERIMENT_COMPLETED"
  static OVERRIDDEN = "OVERRIDDEN"
  static TRAFFIC_NOT_ALLOCATED = "TRAFFIC_NOT_ALLOCATED"
  static NOT_IN_MUTUAL_EXCLUSION_EXPERIMENT = "NOT_IN_MUTUAL_EXCLUSION_EXPERIMENT"
  static IDENTIFIER_NOT_FOUND = "IDENTIFIER_NOT_FOUND"
  static VARIATION_DROPPED = "VARIATION_DROPPED"
  static TRAFFIC_ALLOCATED = "TRAFFIC_ALLOCATED"
  static TRAFFIC_ALLOCATED_BY_TARGETING = "TRAFFIC_ALLOCATED_BY_TARGETING"
  static NOT_IN_EXPERIMENT_TARGET = "NOT_IN_EXPERIMENT_TARGET"

  static FEATURE_FLAG_NOT_FOUND = "FEATURE_FLAG_NOT_FOUND"
  static FEATURE_FLAG_INACTIVE = "FEATURE_FLAG_INACTIVE"
  static INDIVIDUAL_TARGET_MATCH = "INDIVIDUAL_TARGET_MATCH"
  static TARGET_RULE_MATCH = "TARGET_RULE_MATCH"
  static DEFAULT_RULE = "DEFAULT_RULE"

  static REMOTE_CONFIG_PARAMETER_NOT_FOUND = "REMOTE_CONFIG_PARAMETER_NOT_FOUND"

  static IN_APP_MESSAGE_NOT_FOUND = "IN_APP_MESSAGE_NOT_FOUND"
  static IN_APP_MESSAGE_DRAFT = "IN_APP_MESSAGE_DRAFT"
  static IN_APP_MESSAGE_PAUSED = "IN_APP_MESSAGE_PAUSED"
  static IN_APP_MESSAGE_HIDDEN = "IN_APP_MESSAGE_HIDDEN"
  static IN_APP_MESSAGE_TARGET = "IN_APP_MESSAGE_TARGET"
  static NOT_IN_IN_APP_MESSAGE_PERIOD = "NOT_IN_IN_APP_MESSAGE_PERIOD"
  static NOT_IN_IN_APP_MESSAGE_TARGET = "NOT_IN_IN_APP_MESSAGE_TARGET"
}

export const EXPERIMENT_IMPLEMENTATION_TYPE = ["DEFAULT", "SPLIT_URL", "IN_APP_MESSAGE"] as const

export type ExperimentType = "AB_TEST" | "FEATURE_FLAG"
export type ExperimentStatus = "DRAFT" | "RUNNING" | "PAUSED" | "COMPLETED"
export type ExperimentImplementationType = typeof EXPERIMENT_IMPLEMENTATION_TYPE[number]

export interface HackleExperiment {
  readonly key: number
  readonly version: number
}

export class Experiment implements HackleExperiment {
  public static fromJson(type: ExperimentType, dto: ExperimentDto): Experiment | undefined {
    const experimentStatus = Experiment.experimentStatusOrNull(dto.execution.status)
    const variations = dto.variations.map(
      (it) => new Variation(it.id, it.key, it.status === "DROPPED", it.parameterConfigurationId)
    )
    const userOverrides = CollectionUtil.associate(dto.execution.userOverrides, (it) => [it.userId, it.variationId])
    const segmentOverrides = CollectionUtil.mapNotNullOrUndefined(dto.execution.segmentOverrides, (it) =>
      TargetRule.fromJson(it, TargetingType.IDENTIFIER)
    )
    const targetAudiences = CollectionUtil.mapNotNullOrUndefined(dto.execution.targetAudiences, (it) =>
      Target.fromJson(it, TargetingType.PROPERTY)
    )
    const splitUrlTargets = SplitUrlTargets.fromJson(dto.execution.targetUrls)
    const implementationType = parseOrNull(EXPERIMENT_IMPLEMENTATION_TYPE, dto.implementationType)
    const targetRules = CollectionUtil.mapNotNullOrUndefined(dto.execution.targetRules, (it) =>
      TargetRule.fromJson(it, TargetingType.PROPERTY)
    )
    const defaultRule = TargetAction.fromJson(dto.execution.defaultRule)
    return (
      experimentStatus &&
      implementationType &&
      defaultRule &&
      new Experiment(
        dto.id,
        dto.key,
        type,
        dto.identifierType,
        experimentStatus,
        dto.version,
        dto.execution.version,
        variations,
        userOverrides,
        segmentOverrides,
        targetAudiences,
        splitUrlTargets,
        targetRules,
        defaultRule,
        dto.containerId,
        implementationType,
        dto.winnerVariationId
      )
    )
  }

  private static experimentStatusOrNull(executionStatus: string): ExperimentStatus | undefined {
    switch (executionStatus) {
      case "READY":
        return "DRAFT"
      case "RUNNING":
        return "RUNNING"
      case "PAUSED":
        return "PAUSED"
      case "STOPPED":
        return "COMPLETED"
      default:
        log.debug(`Unsupported status [${executionStatus}]`)
        return undefined
    }
  }

  constructor(
    public readonly id: ExperimentId,
    public readonly key: ExperimentKey,
    public readonly type: ExperimentType,
    public readonly identifierType: string,
    public readonly status: ExperimentStatus,
    public readonly version: Long,
    public readonly executionVersion: Long,
    public readonly variations: Variation[],
    public readonly userOverrides: Map<UserId, VariationId>,
    public readonly segmentOverrides: TargetRule[],
    public readonly targetAudiences: Target[],
    public readonly splitUrlTargets: SplitUrlTargets | undefined,
    public readonly targetRules: TargetRule[],
    public readonly defaultRule: TargetAction,
    public readonly containerId: Long | undefined,
    public readonly implementationType: ExperimentImplementationType,
    private readonly winnerVariationId: VariationId | undefined
  ) {}

  _winnerVariationOrNull(): Variation | undefined {
    if (this.winnerVariationId) {
      return this._getVariationByIdOrNull(this.winnerVariationId)
    }

    return undefined
  }

  _getVariationByIdOrNull(variationId: VariationId): Variation | undefined {
    return this.variations.find((it) => it.id === variationId)
  }

  _getVariationByKeyOrNull(variationKey: VariationKey): Variation | undefined {
    return this.variations.find((it) => it.key === variationKey)
  }
}

export class Variation {
  id: VariationId
  key: VariationKey
  isDropped: boolean
  parameterConfigurationId: Long | undefined

  constructor(id: VariationId, key: VariationKey, isDropped: boolean, parameterConfigurationId: Long | undefined) {
    this.id = id
    this.key = key
    this.isDropped = isDropped
    this.parameterConfigurationId = parameterConfigurationId
  }
}

export class Bucket {
  id: number
  seed: number
  slotSize: number
  slots: Slot[]

  constructor(id: number, seed: number, slotSize: number, slots: Slot[]) {
    this.id = id
    this.seed = seed
    this.slotSize = slotSize
    this.slots = slots
  }

  public static fromJson(dto: BucketDto): Bucket {
    return new Bucket(
      dto.id,
      dto.seed,
      dto.slotSize,
      dto.slots.map(
        ({ startInclusive, endExclusive, variationId }) => new Slot(startInclusive, endExclusive, variationId)
      )
    )
  }
}

export class Slot {
  startInclusive: number
  endExclusive: number
  variationId: VariationId

  constructor(startInclusive: number, endExclusive: number, variationId: VariationId) {
    this.startInclusive = startInclusive
    this.endExclusive = endExclusive
    this.variationId = variationId
  }

  contains(slotNumber: number): boolean {
    return this.startInclusive <= slotNumber && slotNumber < this.endExclusive
  }
}

export class EventType {
  id: EventId
  key: EventKey

  constructor(id: EventId, key: EventKey) {
    this.id = id
    this.key = key
  }

  static fromJson(dto: EventTypeDto): EventType {
    return new EventType(dto.id, dto.key)
  }
}

export interface HackleEvent<T = string> {
  key: string
  value?: number
  properties?: Properties<T>
}

export interface HackleUser {
  identifiers: Identifiers
  properties: Properties
  hackleProperties: Properties
  cohorts?: Cohort[]
}

export class IdentifierType {
  static ID = "$id"
  static USER = "$userId"
  static DEVICE = "$deviceId"
  static SESSION = "$sessionId"
  static HACKLE_DEVICE = "$hackleDeviceId"
}

export interface User {
  id?: string
  userId?: string
  deviceId?: string
  identifiers?: Identifiers
  properties?: Properties
}

export interface Identifier {
  readonly type: string
  readonly value: string
}

export interface Identifiers {
  [key: string]: string
}

export const resolveIdentifiers = (user: User): Identifiers => {
  const builder = new IdentifiersBuilder()
  builder.addIdentifiers(user.identifiers || {})
  builder.add(IdentifierType.ID, user.id)
  builder.add(IdentifierType.DEVICE, user.deviceId)

  if (ObjectUtil.isNotNullOrUndefined(user.userId)) {
    builder.add(IdentifierType.USER, user.userId)
  }

  return builder.build()
}

export class IdentifiersBuilder {
  identifiers: Identifiers = {}

  addIdentifiers(identifiers: Identifiers): IdentifiersBuilder {
    for (const identifierType in identifiers) {
      this.add(identifierType, identifiers[identifierType])
    }
    return this
  }

  add(type: string, value: any): IdentifiersBuilder {
    const sanitizeIdentifierValue = IdentifierUtil.sanitizeValue(value)
    if (IdentifierUtil.isValidType(type) && sanitizeIdentifierValue) {
      this.identifiers[type] = value
    } else {
      log.warn(`Invalid user identifier [type=${type}, value=${value}]`)
    }
    return this
  }

  build(): Identifiers {
    return this.identifiers
  }
}

export type PropertyValue = string | boolean | number | Array<string | number> | null | undefined

const isValidUser = (user: User): boolean => {
  return Boolean(
    user.id || user.userId || user.deviceId || (user.identifiers && Object.keys(user.identifiers).length > 0)
  )
}

export const sanitizeUser = (user: any): User | null => {
  const sanitized: User = {}

  sanitized.id = IdentifierUtil.sanitizeValue(user.id) ?? undefined

  sanitized.userId = IdentifierUtil.sanitizeValue(user.userId) ?? undefined

  sanitized.deviceId = IdentifierUtil.sanitizeValue(user.deviceId) ?? undefined

  if (typeof user.properties === "object") {
    sanitized.properties = PropertyUtil.sanitize(user.properties)
  }

  if (typeof user.identifiers === "object") {
    sanitized.identifiers = IdentifierUtil.sanitize(user.identifiers)
  }

  if (isValidUser(sanitized)) {
    return sanitized
  } else {
    return null
  }
}

export interface Properties<T = string | PropertyOperation> {
  [key: string | PropertyOperation]: T extends PropertyOperation ? Properties<string> : PropertyValue
}

export class Target {
  public static fromJson(dto: TargetDto, targetingType: TargetingType): Target | undefined {
    const conditions = CollectionUtil.mapNotNullOrUndefined(dto.conditions, (it) =>
      TargetCondition.fromJson(it, targetingType)
    )
    return new Target(conditions)
  }

  conditions: TargetCondition[]

  constructor(conditions: TargetCondition[]) {
    this.conditions = conditions
  }

  public toJson(): TargetDto {
    return {
      conditions: this.conditions.map((condition) => condition.toJson())
    }
  }
}

export class TargetCondition {
  public static fromJson(dto: TargetConditionDto, targetingType: TargetingType): TargetCondition | undefined {
    const key = TargetKey.fromJson(dto.key)
    if (!key) {
      return undefined
    }
    if (!targetingType.supports(key.type)) {
      return undefined
    }
    const match = TargetMatch.fromJson(dto.match)
    return match && new TargetCondition(key, match)
  }

  key: TargetKey
  match: TargetMatch

  constructor(key: TargetKey, match: TargetMatch) {
    this.key = key
    this.match = match
  }

  public toJson(): TargetConditionDto {
    return {
      key: this.key.toJson(),
      match: this.match.toJson()
    }
  }
}

export class TargetKey {
  public static fromJson(dto: TargetKeyDto): TargetKey | undefined {
    const keyType = parseOrNull(TARGET_KEY_TYPES, dto.type)
    return keyType && new TargetKey(keyType, dto.name)
  }

  type: TargetKeyType
  name: string

  constructor(type: TargetKeyType, name: string) {
    this.type = type
    this.name = name
  }

  public toJson(): TargetKeyDto {
    return {
      type: this.type,
      name: this.name
    }
  }
}

export class TargetMatch {
  public static fromJson(dto: TargetMatchDto): TargetMatch | undefined {
    const matchType = parseOrNull(MATCH_TYPES, dto.type)
    const operator = parseOrNull(MATCH_OPERATORS, dto.operator)
    const valueType = parseOrNull(MATCH_VALUE_TYPES, dto.valueType)
    return matchType && operator && valueType && new TargetMatch(matchType, operator, valueType, dto.values)
  }

  type: MatchType
  operator: MatchOperator
  valueType: MatchValueType
  values: any[]

  constructor(type: MatchType, operator: MatchOperator, valueType: MatchValueType, values: any[]) {
    this.type = type
    this.operator = operator
    this.valueType = valueType
    this.values = values
  }

  public toJson(): TargetMatchDto {
    return {
      operator: this.operator,
      type: this.type,
      valueType: this.valueType,
      values: this.values
    }
  }
}

export class TargetAction {
  public static fromJson(dto: TargetActionDto): TargetAction | undefined {
    const type = parseOrNull(TARGET_ACTION_TYPES, dto.type)
    return type && new TargetAction(type, dto.variationId, dto.bucketId)
  }

  type: TargetActionType
  variationId: number | undefined
  bucketId: number | undefined

  constructor(type: TargetActionType, variationId: number | undefined, bucketId: number | undefined) {
    this.type = type
    this.variationId = variationId
    this.bucketId = bucketId
  }
}

export class TargetRule {
  public static fromJson(dto: TargetRuleDto, targetingType: TargetingType): TargetRule | undefined {
    const target = Target.fromJson(dto.target, targetingType)
    const action = TargetAction.fromJson(dto.action)
    return target && action && new TargetRule(target, action)
  }

  target: Target
  action: TargetAction

  constructor(target: Target, action: TargetAction) {
    this.target = target
    this.action = action
  }
}

export class Segment {
  public static fromJson(dto: SegmentDto): Segment | undefined {
    const segmentType = parseOrNull(SEGMENT_TYPES, dto.type)
    return (
      segmentType &&
      new Segment(
        dto.id,
        dto.key,
        segmentType,
        CollectionUtil.mapNotNullOrUndefined(dto.targets, (it) => Target.fromJson(it, TargetingType.SEGMENT))
      )
    )
  }

  id: Long
  key: SegmentKey
  type: SegmentType
  targets: Target[]

  constructor(id: Long, key: SegmentKey, type: SegmentType, targets: Target[]) {
    this.id = id
    this.key = key
    this.type = type
    this.targets = targets
  }
}

export class Container {
  id: Long
  bucketId: Long
  groups: ContainerGroup[]

  constructor(id: Long, bucketId: Long, groups: ContainerGroup[]) {
    this.id = id
    this.bucketId = bucketId
    this.groups = groups
  }

  public static fromJson(dto: ContainerDto): Container {
    return new Container(
      dto.id,
      dto.bucketId,
      dto.groups.map((it) => ContainerGroup.fromJson(it))
    )
  }

  getGroupOrNull(containerGroupId: Long): ContainerGroup | undefined {
    return this.groups.find((it) => it.id === containerGroupId)
  }
}

export class ContainerGroup {
  public static fromJson(dto: ContainerGroupDto): ContainerGroup {
    return new ContainerGroup(dto.id, dto.experiments)
  }

  id: Long
  experiments: Long[]

  constructor(id: Long, experiments: Long[]) {
    this.id = id
    this.experiments = experiments
  }
}

export class RemoteConfigParameter {
  id: Long
  key: string
  type: MatchValueType
  identifierType: string
  targetRules: RemoteConfigTargetRule[]
  defaultValue: RemoteConfigParameterValue

  constructor(
    id: Long,
    key: string,
    type: MatchValueType,
    identifierType: string,
    targetRules: RemoteConfigTargetRule[],
    defaultValue: RemoteConfigParameterValue
  ) {
    this.id = id
    this.key = key
    this.type = type
    this.identifierType = identifierType
    this.targetRules = targetRules
    this.defaultValue = defaultValue
  }

  public static fromJson(dto: RemoteConfigParameterDto): RemoteConfigParameter | undefined {
    const remoteConfigParameterType = parseOrNull(MATCH_VALUE_TYPES, dto.type)
    const targetRules = CollectionUtil.mapNotNullOrUndefined(dto.targetRules, (it) =>
      RemoteConfigTargetRule.fromJson(it, TargetingType.PROPERTY)
    )
    const defaultValue = RemoteConfigParameterValue.fromJson(dto.defaultValue)
    return (
      remoteConfigParameterType &&
      new RemoteConfigParameter(
        dto.id,
        dto.key,
        remoteConfigParameterType,
        dto.identifierType,
        targetRules,
        defaultValue
      )
    )
  }
}

export class RemoteConfigTargetRule {
  public static fromJson(
    dto: RemoteConfigTargetRuleDto,
    targetingType: TargetingType
  ): RemoteConfigTargetRule | undefined {
    const target = Target.fromJson(dto.target, targetingType)
    return (
      target &&
      new RemoteConfigTargetRule(
        dto.key,
        dto.name,
        target,
        dto.bucketId,
        RemoteConfigParameterValue.fromJson(dto.value)
      )
    )
  }

  key: string
  name: string
  target: Target
  bucketId: Long
  value: RemoteConfigParameterValue

  constructor(key: string, name: string, target: Target, bucketId: Long, value: RemoteConfigParameterValue) {
    this.key = key
    this.name = name
    this.target = target
    this.bucketId = bucketId
    this.value = value
  }
}

export class RemoteConfigParameterValue {
  public static fromJson(dto: RemoteConfigParameterValueDto): RemoteConfigParameterValue {
    return new RemoteConfigParameterValue(dto.id, dto.value)
  }

  id: Long
  rawValue: any

  constructor(id: Long, rawValue: any) {
    this.id = id
    this.rawValue = rawValue
  }
}

function compareValues(a: number, b: number): number {
  return a - b
}

export class Version {
  readonly coreVersion: CoreVersion
  readonly prerelease: MetaVersion
  readonly build: MetaVersion

  constructor(coreVersion: CoreVersion, prerelease: MetaVersion, build: MetaVersion) {
    this.coreVersion = coreVersion
    this.prerelease = prerelease
    this.build = build
  }

  static regExp =
    /^(0|[1-9]\d*)(?:\.(0|[1-9]\d*))?(?:\.(0|[1-9]\d*))?(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/

  static tryParse(value: any): Version | undefined {
    const match = Version.regExp.exec(value)
    if (!match) return undefined

    const [_, major, minor = "0", patch = "0", prerelease, build] = match

    const coreVersion = new CoreVersion(parseInt(major, 10), parseInt(minor, 10), parseInt(patch, 10))

    return new Version(coreVersion, MetaVersion.parse(prerelease), MetaVersion.parse(build))
  }

  compareTo(other: Version): number {
    return this.coreVersion.compareTo(other.coreVersion) || this.prerelease.compareTo(other.prerelease)
  }

  isEqualTo(other: Version): boolean {
    return this.compareTo(other) === 0
  }

  isGreaterThan(other: Version): boolean {
    return this.compareTo(other) > 0
  }

  isGreaterThanOrEqualTo(other: Version): boolean {
    return this.compareTo(other) >= 0
  }

  isLessThan(other: Version): boolean {
    return this.compareTo(other) < 0
  }

  isLessThanOrEqualTo(other: Version): boolean {
    return this.compareTo(other) <= 0
  }
}

export class CoreVersion {
  readonly major: number
  readonly minor: number
  readonly patch: number

  constructor(major: number, minor: number, patch: number) {
    this.major = major
    this.minor = minor
    this.patch = patch
  }

  compareTo(other: CoreVersion): number {
    return (
      compareValues(this.major, other.major) ||
      compareValues(this.minor, other.minor) ||
      compareValues(this.patch, other.patch)
    )
  }
}

export class MetaVersion {
  readonly identifiers: string[]

  constructor(identifiers: string[]) {
    this.identifiers = identifiers
  }

  private static EMPTY = new MetaVersion([])

  static parse(text: string | undefined): MetaVersion {
    if (!text) {
      return MetaVersion.EMPTY
    } else {
      return new MetaVersion(text.split("."))
    }
  }

  isEmpty(): boolean {
    return this.identifiers.length === 0
  }

  isNotEmpty(): boolean {
    return !this.isEmpty()
  }

  compareTo(other: MetaVersion): number {
    if (this.isEmpty() && other.isEmpty()) {
      return 0
    }

    if (this.isEmpty() && other.isNotEmpty()) {
      return 1
    }

    if (this.isNotEmpty() && other.isEmpty()) {
      return -1
    }

    return this.compareIdentifiers(other)
  }

  private compareIdentifiers(other: MetaVersion): number {
    const length = Math.min(this.identifiers.length, other.identifiers.length)
    for (let i = 0; i < length; i++) {
      const result = MetaVersion.compareIdentifiers(this.identifiers[i], other.identifiers[i])
      if (result !== 0) {
        return result
      }
    }
    return compareValues(this.identifiers.length, other.identifiers.length)
  }

  private static numericIdentifierRegExp = /^(0|[1-9]\d*)$/

  private static compareIdentifiers(identifier1: string, identifier2: string): number {
    if (
      MetaVersion.numericIdentifierRegExp.test(identifier1) &&
      MetaVersion.numericIdentifierRegExp.test(identifier2)
    ) {
      return compareValues(+identifier1, +identifier2)
    }

    if (identifier1 === identifier2) {
      return 0
    }

    return identifier1 < identifier2 ? -1 : 1
  }
}

export type HackleValue = string | number | boolean

export const MATCH_TYPES = ["MATCH", "NOT_MATCH"] as const
export const MATCH_VALUE_TYPES = ["STRING", "NUMBER", "BOOLEAN", "VERSION", "JSON", "URL", "NULL", "UNKNOWN"] as const
export const MATCH_OPERATORS = ["IN", "CONTAINS", "STARTS_WITH", "ENDS_WITH", "GT", "GTE", "LT", "LTE"] as const
export const TARGET_ACTION_TYPES = ["VARIATION", "BUCKET"] as const
export const TARGET_KEY_TYPES = [
  "USER_ID",
  "USER_PROPERTY",
  "HACKLE_PROPERTY",
  "SEGMENT",
  "AB_TEST",
  "FEATURE_FLAG",
  "EVENT_PROPERTY",
  "COHORT"
] as const
export const SEGMENT_TYPES = ["USER_ID", "USER_PROPERTY"] as const

export type MatchType = typeof MATCH_TYPES[number]
export type MatchValueType = typeof MATCH_VALUE_TYPES[number]
export type MatchOperator = typeof MATCH_OPERATORS[number]
export type TargetActionType = typeof TARGET_ACTION_TYPES[number]
export type TargetKeyType = typeof TARGET_KEY_TYPES[number]
export type SegmentType = typeof SEGMENT_TYPES[number]

export class TargetingType {
  static IDENTIFIER = new TargetingType("SEGMENT")
  static PROPERTY = new TargetingType(
    "SEGMENT",
    "USER_PROPERTY",
    "HACKLE_PROPERTY",
    "AB_TEST",
    "FEATURE_FLAG",
    "EVENT_PROPERTY",
    "COHORT"
  )
  static SEGMENT = new TargetingType("USER_ID", "USER_PROPERTY", "HACKLE_PROPERTY", "COHORT")

  private supportedKeyTypes: TargetKeyType[]

  constructor(...supportedKeyTypes: TargetKeyType[]) {
    this.supportedKeyTypes = supportedKeyTypes
  }

  supports(keyType: TargetKeyType): boolean {
    return this.supportedKeyTypes.includes(keyType)
  }
}

export const IAM_STATUS = ["ACTIVE", "DRAFT", "PAUSE"] as const
export const IAM_PLATFORM_TYPE = ["WEB", "ANDROID", "IOS"] as const
export const IAM_TIMEUNIT = ["IMMEDIATE", "CUSTOM"] as const
export const IAM_DISPLAY_TYPE = ["MODAL", "BANNER", "BOTTOM_SHEET", "NONE"] as const
export const IAM_LAYOUT_TYPE = ["IMAGE_TEXT", "IMAGE_ONLY", "TEXT_ONLY", "IMAGE", "NONE"] as const
export const IAM_ORIENTATION = ["VERTICAL", "HORIZONTAL"] as const
export const IAM_BEHAVIOR = ["CLICK"] as const
export const IAM_ACTION_TYPE = [
  "CLOSE",
  "WEB_LINK",
  "HIDDEN",
  "LINK_AND_CLOSE",
  "LINK_NEW_TAB",
  "LINK_NEW_TAB_AND_CLOSE",
  "LINK_NEW_WINDOW",
  "LINK_NEW_WINDOW_AND_CLOSE"
] as const
export const IAM_VERTICAL_ALIGNMENT = ["TOP", "MIDDLE", "BOTTOM"] as const
export const IAM_HORIZONTAL_ALIGNMENT = ["LEFT", "CENTER", "RIGHT"] as const
export const IAM_ACTION_AREA = ["BUTTON", "IMAGE", "X_BUTTON"] as const

export type InAppMessageStatus = typeof IAM_STATUS[number]
export type InAppMessagePlatformType = typeof IAM_PLATFORM_TYPE[number]
export type InAppMessageTimeUnit = typeof IAM_TIMEUNIT[number]
export type InAppMessageDisplayType = typeof IAM_DISPLAY_TYPE[number]
export type InAppMessageLayoutType = typeof IAM_LAYOUT_TYPE[number]
export type InAppMessageOrientation = typeof IAM_ORIENTATION[number]
export type InAppMessageBehavior = typeof IAM_BEHAVIOR[number]
export type InAppMessageActionType = typeof IAM_ACTION_TYPE[number]
export type InAppMessageActionArea = typeof IAM_ACTION_AREA[number]
export type InAppMessageVerticalAlignment = typeof IAM_VERTICAL_ALIGNMENT[number]
export type InAppMessageHorizontalAlignment = typeof IAM_HORIZONTAL_ALIGNMENT[number]

export class InAppMessage {
  constructor(
    readonly id: number,
    readonly key: number,
    readonly status: InAppMessageStatus,
    readonly period: InAppMessagePeriod,
    readonly eventTrigger: InAppMessageEventTrigger,
    readonly targetContext: InAppMessageTargetContext,
    readonly messageContext: InAppMessageContext
  ) {}

  static fromJson(dto: InAppMessageDto): InAppMessage | undefined {
    const status = parseOrNull(IAM_STATUS, dto.status)
    if (ObjectUtil.isNullOrUndefined(status)) {
      return undefined
    }

    let period: InAppMessagePeriod
    switch (parseOrNull(IAM_TIMEUNIT, dto.timeUnit)) {
      case "IMMEDIATE":
        period = new InAppMessagePeriodAlways()
        break
      case "CUSTOM":
        if (ObjectUtil.isNullOrUndefined(dto.startEpochTimeMillis)) {
          return undefined
        }
        if (ObjectUtil.isNullOrUndefined(dto.endEpochTimeMillis)) {
          return undefined
        }
        period = new InAppMessagePeriodCustom(dto.startEpochTimeMillis, dto.endEpochTimeMillis)
        break
      default:
        return undefined
    }

    const messageContext = InAppMessageContext.fromJson(dto.messageContext)
    if (ObjectUtil.isNullOrUndefined(messageContext)) {
      return undefined
    }

    const eventTriggerRules = dto.eventTriggerRules.map(InAppMessageEventTriggerRule.fromJson)
    const eventFrequencyCap = InAppMessageEventFrequencyCap.fromJson(dto.eventFrequencyCap)

    return new InAppMessage(
      dto.id,
      dto.key,
      status,
      period,
      new InAppMessageEventTrigger(eventTriggerRules, eventFrequencyCap),
      InAppMessageTargetContext.fromJson(dto.targetContext),
      messageContext
    )
  }

  public supports(platformType: InAppMessagePlatformType): boolean {
    return this.messageContext.platformTypes.includes(platformType)
  }
}

export interface InAppMessagePeriod {
  type: InAppMessageTimeUnit
  startMillisInclusive?: number
  endMillisExclusive?: number

  within(timestamp: number): boolean
}

export class InAppMessagePeriodAlways implements InAppMessagePeriod {
  type: InAppMessageTimeUnit = "IMMEDIATE"

  within(timestamp: number): boolean {
    return true
  }
}

export class InAppMessagePeriodCustom implements InAppMessagePeriod {
  type: InAppMessageTimeUnit = "CUSTOM"

  constructor(public readonly startMillisInclusive: number, public readonly endMillisExclusive: number) {}

  within(timestamp: number): boolean {
    return this.startMillisInclusive <= timestamp && timestamp < this.endMillisExclusive
  }
}

export class InAppMessageEventTrigger {
  constructor(
    public readonly rules: InAppMessageEventTriggerRule[],
    public readonly frequencyCap: InAppMessageEventFrequencyCap | null
  ) {}
}

export class InAppMessageEventTriggerRule {
  constructor(public readonly eventKey: string, public readonly targets: Target[]) {}

  static fromJson(dto: InAppMessageEventTriggerRuleDto): InAppMessageEventTriggerRule {
    return new InAppMessageEventTriggerRule(
      dto.eventKey,
      CollectionUtil.mapNotNullOrUndefined(dto.targets, (it) => Target.fromJson(it, TargetingType.PROPERTY))
    )
  }
}

export class InAppMessageEventFrequencyCap {
  constructor(
    public readonly identifierCaps: InAppMessageIdentifierCap[],
    public readonly durationCap: InAppMessageDurationCap | null
  ) {}

  static fromJson(dto?: InAppMessageEventFrequencyCapDto): InAppMessageEventFrequencyCap | null {
    if (!dto) return null
    const identifierCaps = dto.identifiers.map(InAppMessageIdentifierCap.fromJson)
    const durationCaps = InAppMessageDurationCap.fromJson(dto.duration)
    return new InAppMessageEventFrequencyCap(identifierCaps, durationCaps)
  }
}

export class InAppMessageIdentifierCap {
  constructor(public readonly identifierType: string, public readonly count: number) {}

  static fromJson(dto: InAppMessageIdentifierCapDto): InAppMessageIdentifierCap {
    return new InAppMessageIdentifierCap(dto.identifierType, dto.countPerIdentifier)
  }
}

export class InAppMessageDurationCap {
  constructor(public readonly durationMillis: number, public readonly count: number) {}

  static fromJson(dto?: InAppMessageDurationCapDto): InAppMessageDurationCap | null {
    if (!dto) return null

    const timeUnit = parseOrNull(TIME_UNIT, dto.durationUnit.timeUnit)
    if (!timeUnit) return null

    return new InAppMessageDurationCap(
      TimeUtil.converter[timeUnit](dto.durationUnit.amount, "milliseconds"),
      dto.countPerDuration
    )
  }
}

export class InAppMessageTargetContext {
  constructor(readonly overrides: InAppMessageUserOverride[], readonly targets: Target[]) {}

  static fromJson(dto: InAppMessageTargetContextDto): InAppMessageTargetContext {
    return new InAppMessageTargetContext(
      dto.overrides.map(InAppMessageUserOverride.fromJson),
      CollectionUtil.mapNotNullOrUndefined(dto.targets, (it) => Target.fromJson(it, TargetingType.PROPERTY))
    )
  }
}

export class InAppMessageUserOverride {
  constructor(readonly identifierType: string, readonly identifiers: string[]) {}

  static fromJson(dto: InAppMessageTargetRuleOverrideDto): InAppMessageUserOverride {
    return new InAppMessageUserOverride(dto.identifierType, dto.identifiers)
  }
}

export class InAppMessageContext {
  constructor(
    readonly defaultLang: string,
    readonly experimentContext: InAppMessageExperimentContext | null,
    readonly platformTypes: InAppMessagePlatformType[],
    readonly orientations: InAppMessageOrientation[],
    readonly messages: InAppMessageContextMessage[]
  ) {}

  static fromJson(dto: InAppMessageContextDto): InAppMessageContext | undefined {
    let experiment: InAppMessageExperimentContext | null = null
    if (dto.exposure.type === "AB_TEST" && dto.exposure.key !== null) {
      experiment = new InAppMessageExperimentContext(dto.exposure.key)
    }

    const platformTypes = parseAllOrNull(IAM_PLATFORM_TYPE, dto.platformTypes)
    if (ObjectUtil.isNullOrUndefined(platformTypes)) {
      return undefined
    }

    const orientations = parseAllOrNull(IAM_ORIENTATION, dto.orientations)
    if (ObjectUtil.isNullOrUndefined(orientations)) {
      return undefined
    }

    const messages = CollectionUtil.mapOrUndefined(dto.messages, InAppMessageContextMessage.fromJson)
    if (ObjectUtil.isNullOrUndefined(messages)) {
      return undefined
    }
    return new InAppMessageContext(dto.defaultLang, experiment, platformTypes, orientations, messages)
  }
}

export class InAppMessageExperimentContext {
  constructor(readonly key: number) {}
}

export class InAppMessageContextMessage {
  constructor(
    readonly variationKey: string | null,
    readonly lang: string,
    readonly layout: InAppMessageContextLayout,
    readonly images: InAppMessageImage[],
    readonly text: InAppMessageText | undefined,
    readonly buttons: InAppMessageButton[],
    readonly closeButton: InAppMessageButton | undefined,
    readonly background: InAppMessageBackground,
    readonly action: InAppMessageAction | undefined,
    readonly outerButtons: InAppMessagePositionalButton[],
    readonly innerButtons: InAppMessagePositionalButton[]
  ) {}

  static fromJson(dto: InAppMessageContextMessageDto): InAppMessageContextMessage | undefined {
    const layout = InAppMessageContextLayout.fromJson(dto.layout)
    if (ObjectUtil.isNullOrUndefined(layout)) {
      return undefined
    }
    const images = CollectionUtil.mapOrUndefined(dto.images, InAppMessageImage.fromJson)
    if (ObjectUtil.isNullOrUndefined(images)) {
      return undefined
    }

    const buttons = CollectionUtil.mapOrUndefined(dto.buttons, InAppMessageButton.fromJson)
    if (ObjectUtil.isNullOrUndefined(buttons)) {
      return undefined
    }
    let closeButton: InAppMessageButton | undefined = undefined
    if (ObjectUtil.isNotNullOrUndefined(dto.closeButton)) {
      closeButton = InAppMessageButton.fromJson2(dto.closeButton)
      if (ObjectUtil.isNullOrUndefined(closeButton)) {
        return undefined
      }
    }
    const outerButtons = CollectionUtil.mapOrUndefined(dto.outerButtons, InAppMessagePositionalButton.fromJson)
    const innerButtons = CollectionUtil.mapOrUndefined(dto.innerButtons, InAppMessagePositionalButton.fromJson)

    if (ObjectUtil.isNullOrUndefined(outerButtons) || ObjectUtil.isNullOrUndefined(innerButtons)) {
      return undefined
    }

    return new InAppMessageContextMessage(
      dto.variationKey,
      dto.lang,
      layout,
      images,
      dto.text ? InAppMessageText.fromJson(dto.text) : undefined,
      buttons,
      closeButton,
      new InAppMessageBackground(dto.background.color),
      dto.action ? InAppMessageAction.fromJson(dto.action) : undefined,
      outerButtons,
      innerButtons
    )
  }

  public toJson(): InAppMessageContextMessageDto {
    return {
      variationKey: this.variationKey,
      lang: this.lang,
      layout: {
        layoutType: this.layout.layoutType,
        alignment: this.layout.alignment?.toJson() ?? null,
        displayType: this.layout.displayType
      },
      images: this.images.map((it) => it.toJson()),
      text: this.text?.toJson() ?? null,
      closeButton: this.closeButton?.toCloseButtonJson() ?? null,
      buttons: this.buttons.map((it) => it.toJson()),
      background: {
        color: this.background.color
      },
      action: this.action?.toJson() ?? null,
      outerButtons: this.outerButtons.map((it) => it.toJson()),
      innerButtons: this.innerButtons.map((it) => it.toJson())
    }
  }
}

export class InAppMessageContextLayout {
  constructor(
    readonly displayType: InAppMessageDisplayType,
    readonly layoutType: InAppMessageLayoutType,
    readonly alignment: InAppMessageAlignment | undefined
  ) {}

  static fromJson(dto: InAppMessageLayoutDto): InAppMessageContextLayout | undefined {
    const layoutType = parseOrNull(IAM_LAYOUT_TYPE, dto.layoutType)
    const displayType = parseOrNull(IAM_DISPLAY_TYPE, dto.displayType)
    if (ObjectUtil.isNullOrUndefined(displayType)) {
      return undefined
    }
    if (ObjectUtil.isNullOrUndefined(layoutType)) {
      return undefined
    }

    return new InAppMessageContextLayout(
      displayType,
      layoutType,
      dto.alignment ? InAppMessageAlignment.fromJson(dto.alignment) : undefined
    )
  }
}

export class InAppMessageAlignment {
  constructor(readonly horizontal: InAppMessageHorizontalAlignment, readonly vertical: InAppMessageVerticalAlignment) {}

  static fromJson(dto: InAppMessageAlignmentDto): InAppMessageAlignment | undefined {
    const horizontal = parseOrNull(IAM_HORIZONTAL_ALIGNMENT, dto.horizontal)
    if (ObjectUtil.isNullOrUndefined(horizontal)) {
      return undefined
    }

    const vertical = parseOrNull(IAM_VERTICAL_ALIGNMENT, dto.vertical)
    if (ObjectUtil.isNullOrUndefined(vertical)) {
      return undefined
    }

    return new InAppMessageAlignment(horizontal, vertical)
  }

  toJson(): InAppMessageAlignmentDto {
    return { horizontal: this.horizontal, vertical: this.vertical }
  }
}

export class InAppMessageImage {
  constructor(
    readonly orientation: InAppMessageOrientation,
    readonly imagePath: string,
    readonly action: InAppMessageAction | undefined
  ) {}

  static fromJson(dto: InAppMessageContextImageDto): InAppMessageImage | undefined {
    const orientation = parseOrNull(IAM_ORIENTATION, dto.orientation)
    if (ObjectUtil.isNullOrUndefined(orientation)) {
      return undefined
    }
    let action: InAppMessageAction | undefined = undefined
    if (ObjectUtil.isNotNullOrUndefined(dto.action)) {
      action = InAppMessageAction.fromJson(dto.action)
      if (ObjectUtil.isNullOrUndefined(action)) {
        return undefined
      }
    }
    return new InAppMessageImage(orientation, dto.imagePath, action)
  }

  toJson(): InAppMessageContextImageDto {
    return {
      orientation: this.orientation,
      imagePath: this.imagePath,
      action: this.action?.toJson() ?? null
    }
  }
}

export class InAppMessageText {
  constructor(readonly title: InAppMessageTextAttribute, readonly body: InAppMessageTextAttribute) {}

  static fromJson(dto: InAppMessageContextTextDto): InAppMessageText {
    return new InAppMessageText(
      InAppMessageTextAttribute.fromJson(dto.title),
      InAppMessageTextAttribute.fromJson(dto.body)
    )
  }

  toJson(): InAppMessageContextTextDto {
    return {
      title: {
        text: this.title.text,
        style: {
          textColor: this.title.style.color
        }
      },
      body: {
        text: this.body.text,
        style: {
          textColor: this.body.style.color
        }
      }
    }
  }
}

export class InAppMessageTextAttribute {
  constructor(readonly text: string, readonly style: InAppMessageTextStyle) {}

  static fromJson(dto: InAppMessageContextTextContentDto): InAppMessageTextAttribute {
    return new InAppMessageTextAttribute(dto.text, new InAppMessageTextStyle(dto.style.textColor))
  }
}

export class InAppMessageTextStyle {
  constructor(readonly color: string) {}
}

export class InAppMessageButton {
  constructor(readonly text: string, readonly style: InAppMessageButtonStyle, readonly action: InAppMessageAction) {}

  static fromJson(dto: InAppMessageContextButtonDto): InAppMessageButton | undefined {
    const action = InAppMessageAction.fromJson(dto.action)
    if (ObjectUtil.isNullOrUndefined(action)) {
      return undefined
    }
    return new InAppMessageButton(
      dto.text,
      new InAppMessageButtonStyle(dto.style.textColor, dto.style.bgColor, dto.style.borderColor),
      action
    )
  }

  static fromJson2(dto: InAppMessageContextCloseButtonDto): InAppMessageButton | undefined {
    const action = InAppMessageAction.fromJson(dto.action)
    if (ObjectUtil.isNullOrUndefined(action)) {
      return undefined
    }
    return new InAppMessageButton("✕", new InAppMessageButtonStyle(dto.style.color, "#FFFFFF", "#FFFFFF"), action)
  }

  toJson(): InAppMessageContextButtonDto {
    return {
      text: this.text,
      action: this.action.toJson(),
      style: {
        textColor: this.style.textColor,
        bgColor: this.style.backgroundColor,
        borderColor: this.style.borderColor
      }
    }
  }

  toCloseButtonJson(): InAppMessageContextCloseButtonDto {
    return {
      action: this.action.toJson(),
      style: {
        color: this.style.textColor
      }
    }
  }
}

export class InAppMessagePositionalButton {
  constructor(readonly button: InAppMessageButton, readonly alignment: InAppMessageAlignment) {}

  static fromJson(dto: InAppMessagePositionalButtonDto): InAppMessagePositionalButton | undefined {
    const button = InAppMessageButton.fromJson(dto.button)
    if (ObjectUtil.isNullOrUndefined(button)) {
      return undefined
    }

    const alignment = InAppMessageAlignment.fromJson(dto.alignment)
    if (ObjectUtil.isNullOrUndefined(alignment)) {
      return undefined
    }

    return new InAppMessagePositionalButton(button, alignment)
  }

  toJson(): InAppMessagePositionalButtonDto {
    return { button: this.button.toJson(), alignment: this.alignment.toJson() }
  }
}

export class InAppMessageButtonStyle {
  constructor(readonly textColor: string, readonly backgroundColor: string, readonly borderColor: string) {}
}

export class InAppMessageBackground {
  constructor(readonly color: string) {}
}

export class InAppMessageAction {
  constructor(
    readonly behavior: InAppMessageBehavior,
    readonly type: InAppMessageActionType,
    readonly value: string | undefined
  ) {}

  static fromJson(dto: InAppMessageContextActionDto): InAppMessageAction | undefined {
    const behavior = parseOrNull(IAM_BEHAVIOR, dto.behavior)
    if (ObjectUtil.isNullOrUndefined(behavior)) {
      return undefined
    }
    const type = parseOrNull(IAM_ACTION_TYPE, dto.type)
    if (ObjectUtil.isNullOrUndefined(type)) {
      return undefined
    }
    return new InAppMessageAction(behavior, type, dto.value ?? undefined)
  }

  toJson(): InAppMessageContextActionDto {
    return {
      behavior: this.behavior,
      type: this.type,
      value: this.value ?? null
    }
  }
}

export class Cohort {
  constructor(readonly id: number) {}
}

export interface Sdk {
  readonly key: string
  readonly name: string
  readonly version: string
}

// Split URL
export enum SplitUrlRedirectType {
  MANUAL = "MANUAL"
}

const isSplitUrlRedirectType = (value: unknown): value is SplitUrlRedirectType =>
  Object.values(SplitUrlRedirectType).includes(value as any)

export class SplitUrlConfig {
  constructor(
    readonly needRedirect: boolean,
    readonly redirectType: SplitUrlRedirectType | null,
    readonly redirectUrl: string | null
  ) {}

  static fromDto(dto: SplitUrlConfigDto): SplitUrlConfig {
    const needRedirect = dto.needRedirect
    const redirectType = isSplitUrlRedirectType(dto.properties.type) ? dto.properties.type : null
    const redirectUrl = dto.properties.value

    return new SplitUrlConfig(needRedirect, redirectType, redirectUrl)
  }
}

export class SplitUrlTargets {
  public static fromJson(dto: ExecutionTargetUrlsDto | null): SplitUrlTargets | undefined {
    if (dto === null) {
      return undefined
    }

    const includeTargets = CollectionUtil.mapNotNullOrUndefined(dto.match, (it) =>
      Target.fromJson(it, TargetingType.PROPERTY)
    )
    const excludeTargets = CollectionUtil.mapNotNullOrUndefined(dto.notMatch, (it) =>
      Target.fromJson(it, TargetingType.PROPERTY)
    )

    return new SplitUrlTargets(includeTargets, excludeTargets)
  }

  constructor(readonly includeTargets: Target[], readonly excludeTargets: Target[]) {}
}
