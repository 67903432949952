import { InAppMessagePresentationContext } from "../../presentation/InAppMessagePresenter"

export type InAppMessageRenderType = "pc" | "mobile"

export default class InAppMessageRenderTypeResolver {
  private mobileOSRegex = /(iOS|Android|iPad|iPhone)/g
  private mobileDeviceRegex = /(mobile|phone|tablet)/g

  private isMobileOS(context: InAppMessagePresentationContext) {
    const hackleProperties = context.user.hackleProperties
    const osName = hackleProperties.osName
    return typeof osName === "string" && Boolean(osName.match(this.mobileOSRegex))
  }

  private isMobileDeviceType(context: InAppMessagePresentationContext) {
    const hackleProperties = context.user.hackleProperties
    const deviceType = hackleProperties.deviceType
    return typeof deviceType === "string" && Boolean(deviceType.match(this.mobileDeviceRegex))
  }

  resolve(context: InAppMessagePresentationContext): InAppMessageRenderType {
    if (this.isMobileOS(context) || this.isMobileDeviceType(context)) return "mobile"
    return "pc"
  }
}
