import { EventRetryManager } from "../../../core/internal/event/EventRetryManager"
import { EventDto } from "../../../core/internal/event/dto"
import { EventRepository } from "../../../core/internal/event/EventRepository"
import { EventTransport } from "../../../core/internal/event/EventTransport"
import { Lifecycle } from "../../../core/internal/lifecycle/Lifecycle"
import { LifecycleChangeListener } from "../../../core/internal/lifecycle/LifecycleChangeListener"
import Logger from "../../../core/internal/logger"

export class BrowserEventRetryManager implements EventRetryManager, LifecycleChangeListener {
  constructor(private readonly repository: EventRepository<EventDto>, private readonly transport: EventTransport) {}

  add(events: EventDto[]): Promise<void> {
    return this.repository.add(events)
  }

  delete(events: EventDto[]): Promise<void> {
    return this.repository.delete(events)
  }

  async flush(): Promise<void> {
    const events = await this.repository.read()
    if (events.length === 0) {
      return
    }
    const response = await this.transport.send(events)
    if (response.isSuccessful() || response.isClientError()) {
      await this.delete(events)
    }
  }

  onLifecycleChanged(lifecycle: Lifecycle, _: number): void {
    switch (lifecycle) {
      case "visible":
      case "pageshow":
        this.flush().catch((e) => Logger.log.warn(`Failed to flush retry events: ${e}`))
        return
      case "blur":
      case "focus":
      case "hidden":
      case "locationChange":
      case "pagehide":
        return
    }
  }
}
