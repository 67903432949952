import { v4 as uuid4 } from "uuid"
import { COOKIE_HID_KEY, COOKIE_USER_ID_KEY } from "../../config"
import { CookieStorage } from "../storage/CookieStorage.browser"
import { Lifecycle } from "../../core/internal/lifecycle/Lifecycle"
import { LifecycleChangeListener } from "../../core/internal/lifecycle/LifecycleChangeListener"

export class IdentifierManager implements LifecycleChangeListener {
  initialize() {
    const id = CookieStorage.getInstance().getItem(COOKIE_HID_KEY)
    if (!id) {
      generateIdentifier()
    }
  }

  onLifecycleChanged(lifecycle: Lifecycle, _: number): void {
    switch (lifecycle) {
      case "hidden":
      case "pagehide":
        return removeUserId()
      case "blur":
      case "focus":
      case "locationChange":
      case "visible":
      case "pageshow":
        return
    }
  }
}

export function getUserId(): string {
  const cookieStorage = CookieStorage.getInstance()
  const userId = cookieStorage.getItem(COOKIE_USER_ID_KEY)
  if (userId) {
    return userId
  }
  const id = cookieStorage.getItem(COOKIE_HID_KEY)
  if (id) {
    return id
  } else {
    return generateIdentifier()
  }
}

export function setUserId(userId: string): void {
  CookieStorage.getInstance().setItem(COOKIE_USER_ID_KEY, userId)
}

export function removeUserId(): void {
  CookieStorage.getInstance().removeItem(COOKIE_USER_ID_KEY)
}

export function generateIdentifier(): string {
  const id = uuid4()
  CookieStorage.getInstance().setItem(COOKIE_HID_KEY, id)
  return id
}
