import { BrowserHackleClient } from "../../../hackle/index.browser"
import Logger from "../logger"
import ObjectUtil from "../util/ObjectUtil"
import { Clock, SystemClock } from "../util/TimeUtil"
import { Lifecycle } from "./Lifecycle"
import { LifecycleChangeListener } from "./LifecycleChangeListener"

const log = Logger.log

export class LifecycleManager {
  private readonly listeners: LifecycleChangeListener[] = []
  private _initialized: boolean = false

  constructor(private readonly clock: Clock = SystemClock.instance) {}

  install(browserName: string | null = null): void {
    try {
      // bind LifecycleManager instance for use when overriding history methods
      const changeLifecycle = this.changeLifecycle.bind(this)

      // add page events for detect page initialization and termination
      window.addEventListener("pageshow", () => changeLifecycle("pageshow"))
      window.addEventListener("pagehide", () => changeLifecycle("pagehide"))

      // add visibilitychange events for detect tab change, tab close, and more
      document.addEventListener("visibilitychange", () => changeLifecycle(document.visibilityState))

      // add blur, focus events for detect browser focus changes
      window.addEventListener("blur", () => changeLifecycle("blur"))
      window.addEventListener("focus", () => changeLifecycle("focus"))

      // use the beforeunload event because the safari browser cannot detect when the page ends
      if (LifecycleManager.isSafariBrowser(browserName)) {
        window.addEventListener("beforeunload", () => changeLifecycle("hidden"))
      }

      history.pushState = ((f) =>
        function pushState() {
          try {
            // @ts-ignore
            var ret = f.apply(history, arguments)
            changeLifecycle("locationChange")
            return ret
          } catch (e) {
            if (e instanceof Error || typeof e === "string") {
              log.error(e)
            }
          }
        })(history.pushState)

      history.replaceState = ((f) =>
        function replaceState() {
          try {
            // @ts-ignore
            var ret = f.apply(history, arguments)
            changeLifecycle("locationChange")
            return ret
          } catch (e) {
            if (e instanceof Error || typeof e === "string") {
              log.error(e)
            }
          }
        })(history.replaceState)

      window.addEventListener("popstate", () => {
        try {
          changeLifecycle("locationChange")
        } catch (e) {
          if (e instanceof Error || typeof e === "string") {
            log.error(e)
          }
        }
      })
    } catch (e) {
      if (e instanceof Error || typeof e === "string") {
        log.error(e)
      }
    }
  }

  addListener(listener: LifecycleChangeListener): void {
    this.listeners.push(listener)
  }

  initialize() {
    this._initialized = true
    this.changeLifecycle("pageshow")
  }

  private changeLifecycle(lifecycle: Lifecycle): void {
    if (this._initialized) {
      this.publish(lifecycle)
    }
  }

  private publish(lifecycle: Lifecycle) {
    const timestamp = this.clock.currentMillis()
    this.listeners.forEach((listener) => {
      listener.onLifecycleChanged(lifecycle, timestamp)
    })
  }

  private static isSafariBrowser(browserName: string | null): boolean {
    if (ObjectUtil.isNullOrUndefined(browserName)) {
      return false
    }

    return browserName.toLowerCase().includes("safari")
  }
}
