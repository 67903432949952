import { SplitUrlContext } from "./SplitUrlContext"
import WorkspaceFetcher from "../../core/internal/workspace/WorkspaceFetcher"
import { SplitUrlMatcher } from "./SplitUrlMatcher"
import HackleCore from "../../core/HackleCore"
import { SplitUrlRedirectedCookieStorage } from "./SplitUrlRedirectedCookieStorage"
import { HackleUser } from "../../index.browser"

export class SplitUrlDeterminer {
  constructor(
    private readonly workspaceFetcher: WorkspaceFetcher,
    private readonly splitUrlMatcher: SplitUrlMatcher,
    private readonly splitUrlRedirectedCookieStorage: SplitUrlRedirectedCookieStorage,
    private readonly core: HackleCore
  ) {}

  determineOrNull(user: HackleUser): SplitUrlContext | null {
    if (this.splitUrlRedirectedCookieStorage.alreadyRedirected()) {
      this.splitUrlRedirectedCookieStorage.clear()
      return null
    }

    const workspace = this.workspaceFetcher.get()
    if (!workspace) return null

    for (const experiment of workspace.getSplitUrlExperiments()) {
      const isMatch = this.splitUrlMatcher.matches(workspace, experiment, user)
      if (isMatch) {
        const decision = this.core.experiment(experiment.key, user, "A")
        return new SplitUrlContext(workspace, experiment, decision, user)
      }
    }

    return null
  }
}
