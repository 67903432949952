import { Synchronizer } from "./Synchronizer"
import Logger from "../logger"

export class CompositeSynchronizer implements Synchronizer {
  private readonly synchronizers: Synchronizer[] = []

  add(synchronizer: Synchronizer) {
    this.synchronizers.push(synchronizer)
  }

  async sync(): Promise<void> {
    await Promise.all(this.synchronizers.map((it) => this.safe(it.sync())))
  }

  async close(): Promise<void> {
    await Promise.all(this.synchronizers.map((it) => this.safe(it.close())))
  }

  private async safe(promise: Promise<void>): Promise<void> {
    try {
      await promise
    } catch (e) {
      Logger.log.error(`Failed to sync: ${e}`)
    }
  }
}
