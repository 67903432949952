import { Campaign, CampaignDomain, EMPTY_DOMAIN } from "./Campaign"
import LocationSearchParser from "../property/LocationSearchParser"
import { DomainUtil } from "../util/DomainUtil"
import ObjectUtil from "../../core/internal/util/ObjectUtil"
import { Strings } from "../../core/internal/util/Strings"
import { PageManager } from "../../core/internal/page/PageManager"

export class CampaignParser {
  // Campaign value must not be an empty string
  private static CAMPAIGN_QUERY_CONDITION: (key: string, value: string) => boolean = (key, value) =>
    Strings.isNotEmptyString(value)

  constructor(private readonly pageManager: PageManager) {}

  parse(search?: string): Campaign {
    const params = this.params(search)
    return this.resolve(params)
  }

  private params(search: string | undefined): Record<string, string | undefined> {
    const _search = ObjectUtil.isNotNullOrUndefined(search) ? search : this.pageManager.currentPage.queryParameter
    return new LocationSearchParser(_search).getQueryParams(CampaignParser.CAMPAIGN_QUERY_CONDITION)
  }

  private resolve(params: Record<string, string | undefined>): Campaign {
    return {
      utm_campaign: params[UTM_CAMPAIGN],
      utm_content: params[UTM_CONTENT],
      utm_id: params[UTM_ID],
      utm_medium: params[UTM_MEDIUM],
      utm_source: params[UTM_SOURCE],
      utm_term: params[UTM_TERM],

      gclid: params[GCLID],
      fbclid: params[FBCLID]
    }
  }

  parseDomain(): CampaignDomain {
    if (typeof window === "undefined") {
      return EMPTY_DOMAIN
    }

    const referrerDomain = (): string | undefined => {
      const referrer = this.pageManager.currentPage.referrer
      if (!referrer) return undefined
      const referrerHostname = new URL(referrer).hostname
      return DomainUtil.extractDomain(referrerHostname)
    }

    return {
      current: DomainUtil.extractDomain(this.pageManager.currentPage.hostname),
      referrer: referrerDomain()
    }
  }
}

export const UTM_CAMPAIGN = "utm_campaign"
export const UTM_CONTENT = "utm_content"
export const UTM_ID = "utm_id"
export const UTM_MEDIUM = "utm_medium"
export const UTM_SOURCE = "utm_source"
export const UTM_TERM = "utm_term"

export const GCLID = "gclid"
export const FBCLID = "fbclid"
