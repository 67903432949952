import Workspace from "../../core/internal/workspace/Workspace"
import { Decision, Experiment, HackleUser } from "../../core/internal/model/model"

export class SplitUrlContext {
  constructor(
    readonly workspace: Workspace,
    readonly experiment: Experiment,
    readonly decision: Decision,
    readonly user: HackleUser
  ) {}
}
