import Workspace from "../../core/internal/workspace/Workspace"
import { Experiment, HackleUser } from "../../core/internal/model/model"
import TargetMatcher from "../../core/internal/evaluation/match/TargetMatcher"
import { EvaluatorContext, EvaluatorKey, EvaluatorRequest } from "../../core/internal/evaluation/evalautor/Evaluator"
import { v4 as uuid4 } from "uuid"

export class SplitUrlMatcher {
  constructor(private readonly targetMatcher: TargetMatcher) {}

  matches(workspace: Workspace, experiment: Experiment, user: HackleUser): boolean {
    if (experiment.implementationType !== "SPLIT_URL") return false
    if (!experiment.splitUrlTargets) return false
    const { splitUrlTargets } = experiment

    const request = SplitUrlEvaluatorRequest.of(workspace, user)

    if (splitUrlTargets.excludeTargets.length > 0) {
      if (this.targetMatcher.anyMatches(request, EvaluatorContext.create(), splitUrlTargets.excludeTargets)) {
        return false
      }
    }

    return (
      splitUrlTargets.includeTargets.length > 0 &&
      this.targetMatcher.anyMatches(request, EvaluatorContext.create(), splitUrlTargets.includeTargets)
    )
  }
}

class SplitUrlEvaluatorRequest implements EvaluatorRequest {
  constructor(readonly key: EvaluatorKey, readonly user: HackleUser, readonly workspace: Workspace) {}

  static of(workspace: Workspace, user: HackleUser): SplitUrlEvaluatorRequest {
    return new SplitUrlEvaluatorRequest(new EvaluatorKey("SPLIT_URL", uuid4()), user, workspace)
  }
}
