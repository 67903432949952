export class Page {
  constructor(
    readonly pagePath: string = "",
    readonly host: string = "",
    readonly hostname: string = "",
    readonly url: string = "",
    readonly referrer: string = "",
    readonly queryParameter: string = "",
    readonly protocol: string = ""
  ) {}

  isEquals(other: Page): boolean {
    return this.url === other.url
  }

  get pageTitle(): string {
    if (typeof window === "undefined" || typeof document === "undefined") {
      return ""
    }

    // The page title changes dynamically, so window.document.title is returned each time.
    return window.document.title
  }

  static createWithCurrentPage(): Page {
    if (typeof window === "undefined" || typeof document === "undefined") {
      return new Page()
    }

    return new Page(
      window.location.pathname,
      window.location.host,
      window.location.hostname,
      window.location.href,
      window.document.referrer,
      window.location.search,
      window.location.protocol
    )
  }

  toProperties(): Record<string, string> {
    return {
      pagePath: this.pagePath,
      pageTitle: this.pageTitle,
      host: this.host,
      url: this.url,
      referrer: this.referrer,
      queryParameter: this.queryParameter,
      protocol: this.protocol.replace(":", "")
    }
  }
}
