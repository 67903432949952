import { Lifecycle } from "../lifecycle/Lifecycle"
import { LifecycleChangeListener } from "../lifecycle/LifecycleChangeListener"
import { Page } from "./Page"
import { PageListener } from "./PageListener"

export class PageManager implements LifecycleChangeListener {
  private readonly listeners: PageListener[] = []
  private _currentPage: Page | null = null

  get currentPage(): Page {
    // If the current page does not exist in the getter function so that pageStart can be started lazily
    // a new page is created and returned.
    return this._currentPage ?? Page.createWithCurrentPage()
  }

  private updatePage(page: Page, timestamp: number): void {
    const previousPage = this._currentPage
    if (previousPage && page.isEquals(previousPage)) {
      return
    }

    if (previousPage) {
      this.publishEnd(previousPage, timestamp)
    }

    this._currentPage = page
    this.publishStart(page, timestamp)
  }

  private publishStart(page: Page, timestamp: number): void {
    for (const listener of this.listeners) {
      listener.onPageStarted(page, timestamp)
    }
  }

  private publishEnd(page: Page, timestamp: number): void {
    for (const listener of this.listeners) {
      listener.onPageEnded(page, timestamp)
    }
  }

  onLifecycleChanged(lifecycle: Lifecycle, timestamp: number): void {
    switch (lifecycle) {
      case "pageshow":
      case "locationChange":
        const page = Page.createWithCurrentPage()
        return this.updatePage(page, timestamp)
      case "visible":
      case "pagehide":
      case "blur":
      case "focus":
      case "hidden":
        return
    }
  }

  addListener(listener: PageListener): void {
    this.listeners.push(listener)
  }
}
