import EventProcessor from "../../../core/internal/event/EventProcessor"
import UserEvent from "../../../core/internal/event/UserEvent"
import { decorateEventWithSession, SessionManager } from "../../../core/internal/session/SessionManager"
import { UserManager } from "../../../core/internal/user/UserManager"
import { SessionEventTracker } from "../../session/track/index.browser"
import { Lifecycle } from "../../../core/internal/lifecycle/Lifecycle"
import { LifecycleChangeListener } from "../../../core/internal/lifecycle/LifecycleChangeListener"
import { UserEventDedupDeterminer } from "../dedup/UserEventDedupDeterminer"

export default class BrowserEventProcessor implements EventProcessor, LifecycleChangeListener {
  constructor(
    private readonly delegate: EventProcessor,
    private readonly eventDedupDeterminer: UserEventDedupDeterminer,
    private readonly sessionManager: SessionManager,
    private readonly userManager: UserManager
  ) {}

  process(event: UserEvent): void {
    if (!SessionEventTracker.isSessionEvent(event)) {
      this.sessionManager.startNewSessionIfNeeded(this.userManager.currentUser, event.timestamp)
    }

    if (this.eventDedupDeterminer.isDedupTarget(event)) {
      return
    }

    const sessionDecoratedEvent = decorateEventWithSession(event.copyWithUser(event.user), this.sessionManager)
    this.delegate.process(sessionDecoratedEvent)
  }

  flush(sync: boolean): void {
    this.delegate.flush(sync)
  }

  close(): void {
    this.delegate.close()
  }

  onLifecycleChanged(lifecycle: Lifecycle, _: number): void {
    switch (lifecycle) {
      case "hidden":
      case "pagehide":
        return this.flush(true)
      case "blur":
      case "focus":
      case "locationChange":
      case "visible":
      case "pageshow":
        return
    }
  }
}
