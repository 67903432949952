import { ScheduledJob, Scheduler } from "./Scheduler"
import Logger from "../logger"

export type TimerScheduleType = "setTimeout" | "setInterval"

export class TimerScheduler implements Scheduler {
  constructor(private readonly scheduleType: TimerScheduleType) {}

  schedule(delayMillis: number, task: () => void): ScheduledJob {
    const job = new TimeoutScheduledJob(delayMillis, task)
    job.start()
    return job
  }

  schedulePeriodically(delayMillis: number, intervalMillis: number, task: () => void): ScheduledJob {
    let job: TimerPeriodicScheduledJob
    switch (this.scheduleType) {
      case "setTimeout":
        job = new TimeoutPeriodicScheduledJob(delayMillis, intervalMillis, task)
        break
      case "setInterval":
        job = new IntervalPeriodicScheduledJob(delayMillis, intervalMillis, task)
        break
    }
    job.start()
    return job
  }
}

class TimeoutScheduledJob implements ScheduledJob {
  private timeout: ReturnType<typeof setTimeout> | null

  constructor(private readonly delayMillis: number, private readonly task: () => void) {
    this.timeout = null
  }

  start() {
    this.timeout = setTimeout(() => this.task(), this.delayMillis)
  }

  cancel(): void {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }
}

interface TimerPeriodicScheduledJob extends ScheduledJob {
  start(): void

  cancel(): void
}

class TimeoutPeriodicScheduledJob implements TimerPeriodicScheduledJob {
  private timeout: ReturnType<typeof setTimeout> | null

  constructor(
    private readonly delayMillis: number,
    private readonly intervalMillis: number,
    private readonly task: () => void
  ) {
    this.timeout = null
  }

  start() {
    this.timeout = setTimeout(() => this.run(), this.delayMillis)
  }

  private run(): void {
    try {
      this.task()
    } catch (e) {
      Logger.log.error(`Failed to run task: ${e}`)
    }
    this.timeout = setTimeout(() => this.run(), this.intervalMillis)
  }

  cancel(): void {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }
}

class IntervalPeriodicScheduledJob implements TimerPeriodicScheduledJob {
  private timeout: ReturnType<typeof setTimeout> | null
  private interval: ReturnType<typeof setInterval> | null

  constructor(
    private readonly delayMillis: number,
    private readonly intervalMillis: number,
    private readonly task: () => void
  ) {
    this.timeout = null
    this.interval = null
  }

  start(): void {
    this.timeout = setTimeout(() => {
      this.run()
      this.interval = setInterval(() => this.run(), this.intervalMillis)
    }, this.delayMillis)
  }

  private run() {
    try {
      this.task()
    } catch (e) {
      Logger.log.error(`Failed to run task: ${e}`)
    }
  }

  cancel(): void {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  }
}
