import { InAppMessagePresentationContext } from "../../presentation/InAppMessagePresenter"
import { InAppMessageUi } from "../InAppMessageUi"
import { InAppMessageView } from "./InAppMessageView"
import { ScriptInAppMessageView } from "./ScriptInAppMessageView"
import { InAppMessageRenderScriptLoader } from "../script/InAppMessageRenderScriptLoader"
import InAppMessageRenderTypeResolver from "../internal/InAppMessageRenderTypeResolver"

export class InAppMessageViewFactory {
  constructor(private readonly scriptLoader: InAppMessageRenderScriptLoader) {}

  static create(scriptLoader: InAppMessageRenderScriptLoader): InAppMessageViewFactory {
    return new InAppMessageViewFactory(scriptLoader)
  }

  create(context: InAppMessagePresentationContext, ui: InAppMessageUi): InAppMessageView | null {
    const renderTypeResolver = new InAppMessageRenderTypeResolver()

    switch (context.message.layout.displayType) {
      case "MODAL":
      case "BANNER":
      case "BOTTOM_SHEET":
        return new ScriptInAppMessageView(this.scriptLoader, context, ui, renderTypeResolver)
      case "NONE":
        return null
    }
  }
}
