import UrlUtil from "../../core/internal/util/UrlUtil"

export default class LocationSearchParser {
  search: string

  constructor(search: string) {
    this.search = search
  }

  getQueryParam(name: string): string {
    const params = this.getQueryParams(() => true)
    return params[name] || ""
  }

  getQueryParams(condition?: (key: string, value: string) => boolean): Record<string, string | undefined> {
    const params: { [key: string]: string } = {}
    const queries = this.search.substring(1).split("&")

    for (const query of queries) {
      const [key, value] = query.split("=")
      if (!condition || condition(key, value)) {
        const decodedValue = UrlUtil.tryDecodeURIComponent(value)
        if (decodedValue) {
          params[key] = decodedValue
        }
      }
    }

    return params
  }
}
