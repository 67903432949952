import HackleCore from "../../HackleCore"
import { HackleEvent, HackleUser } from "../model/model"
import { PageEventTracker } from "../page/PageEventTracker"
import { Engagement } from "./Engagement"
import { EngagementListener } from "./EngagementListener"

export class EngagementEventTracker implements EngagementListener {
  private static readonly ENGAGEMENT_EVENT_KEY = "$engagement"
  private static readonly ENGAGEMENT_TIME_PROPERTY_KEY = "$engagement_time_ms"

  constructor(private readonly core: HackleCore) {}

  onEngagement(engagement: Engagement, user: HackleUser, timestamp: number): void {
    const hackleEvent: HackleEvent = {
      key: EngagementEventTracker.ENGAGEMENT_EVENT_KEY,
      properties: {
        [PageEventTracker.PAGE_NAME_PROPERTY_KEY]: engagement.page.pageTitle,
        [EngagementEventTracker.ENGAGEMENT_TIME_PROPERTY_KEY]: engagement.durationMillis
      }
    }

    this.core.track(hackleEvent, user, timestamp)
  }
}
