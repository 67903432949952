import { HackleUser } from "../model/model"
import { Page } from "../page/Page"
import { PageListener } from "../page/PageListener"
import { PageManager } from "../page/PageManager"
import { UserManager } from "../user/UserManager"
import ObjectUtil from "../util/ObjectUtil"
import { Lifecycle } from "../lifecycle/Lifecycle"
import { LifecycleChangeListener } from "../lifecycle/LifecycleChangeListener"
import { Engagement } from "./Engagement"
import { EngagementListener } from "./EngagementListener"
import Logger from "../logger"

const log = Logger.log

export class EngagementManager implements LifecycleChangeListener, PageListener {
  private readonly listeners: EngagementListener[] = []
  private _lastEngagementTime: number | null = null

  constructor(
    private readonly userManager: UserManager,
    private readonly pageManager: PageManager,
    private readonly minimumEngagementDurationMillis: number
  ) {}

  get lastEngagementTime(): number | null {
    return this._lastEngagementTime
  }

  startEngagement(timestamp: number): void {
    log.debug(`startEngagement timestamp:${timestamp}, page:${this.pageManager.currentPage.url}`)

    this._lastEngagementTime = timestamp
  }

  endEngagement(page: Page, timestamp: number): void {
    if (ObjectUtil.isNullOrUndefined(this._lastEngagementTime)) return

    const startTimestamp = this._lastEngagementTime
    this._lastEngagementTime = timestamp

    const durationMillis = timestamp - startTimestamp
    if (durationMillis < this.minimumEngagementDurationMillis) {
      return
    }

    const engagement: Engagement = { page, durationMillis }
    this.publish(engagement, this.userManager.resolve(), timestamp)
  }

  private publish(engagement: Engagement, user: HackleUser, timestamp: number): void {
    log.debug(`onEngagement timestamp:${timestamp}, page:${this.pageManager.currentPage.url}`)

    for (const listener of this.listeners) {
      listener.onEngagement(engagement, user, timestamp)
    }
  }

  onPageStarted(_: Page, timestamp: number): void {
    this.startEngagement(timestamp)
  }

  onPageEnded(page: Page, timestamp: number): void {
    this.endEngagement(page, timestamp)
  }

  onLifecycleChanged(lifecycle: Lifecycle, timestamp: number): void {
    switch (lifecycle) {
      case "visible":
      case "focus":
        return this.startEngagement(timestamp)
      case "pagehide":
      case "hidden":
      case "blur":
        return this.endEngagement(this.pageManager.currentPage, timestamp)
      case "pageshow":
      case "locationChange":
        return
    }
  }

  addListener(listener: EngagementListener): void {
    this.listeners.push(listener)
  }
}
