import Logger from "../core/internal/logger"
import HackleClient from "../core/HackleClient"
import HackleCore from "../core/HackleCore"
import {
  Decision,
  DecisionReason,
  FeatureFlagDecision,
  HackleEvent,
  HackleRemoteConfig,
  Properties,
  sanitizeUser,
  User,
  VariationKey
} from "../core/internal/model/model"
import { DEFAULT_ON_READY_TIMEOUT } from "../config"
import HackleRemoteConfigImpl from "./remoteconfig/index.browser"
import { DecisionMetrics } from "../core/internal/metrics/monitoring/MonitoringMetricRegistry"
import { TimerSample } from "../core/internal/metrics/Timer"
import PropertyUtil from "../core/internal/util/PropertyUtil"
import IdentifierUtil from "../core/internal/util/IdentifierUtil"
import { Metrics } from "../core/internal/metrics/Metrics"
import { HackleUserExplorerBase } from "../core/internal/user/UserExplorer"
import { PropertyOperations, PropertyOperationsBuilder } from "./property/PropertyOperations"
import { UserManager } from "../core/internal/user/UserManager"
import { SessionManager } from "../core/internal/session/SessionManager"
import { PollingSynchronizer } from "../core/internal/sync/PollingSynchronizer"
import { Promises } from "../core/internal/util/Promises"
import { Throttler } from "../core/internal/throttler/Throttler"
import { PageEventTracker } from "../core/internal/page/PageEventTracker"
import { Page } from "../core/internal/page/Page"
import { Updated } from "../core/internal/util/Updated"

const log = Logger.log

export interface DevTools {
  manager: { userExplorer(userExplorer: HackleUserExplorerBase): void; close(): void }
  userExplorer?: HackleUserExplorerBase
}

export interface PageView {
  user?: User
  pathUrl?: string
}

export interface BrowserHackleClient extends HackleClient {
  /**
   * Returns current session ID
   *
   * @return {String} if session is unavailable, returns 0.ffffffff
   * @since 11.8.0
   *
   */
  getSessionId(): string

  getUser(): User

  setUser(user: User): Promise<void>

  setUserId(userId: string | number | undefined): Promise<void>

  setDeviceId(deviceId: string): Promise<void>

  setUserProperty(key: string, value: any): Promise<void>

  setUserProperties(properties: Properties): Promise<void>

  /**
   * Updates the user's properties.
   *
   * @param operations Property operations to update user properties.
   *
   */
  updateUserProperties(operations: PropertyOperations): Promise<void>

  resetUser(): Promise<void>

  /**
   * Determine the variation to expose to the user for experiment.
   *
   * This method return the {"A"} if:
   * - The experiment key is invalid
   * - The experiment has not started yet
   * - The user is not allocated to the experiment
   * - The determined variation has been dropped
   *
   * @param experimentKey     the unique key of the experiment.
   * @param user              the user to participate in the experiment. MUST NOT be null.
   * @param defaultVariation  the default variation of the experiment.
   *
   * @return string the decided variation for the user, or the default variation.
   */
  variation(experimentKey: number, user?: User | string, defaultVariation?: string): string

  /**
   * Determine the variation to expose to the user for experiment, and returns an object that
   * describes the way the variation was determined.
   *
   * @param experimentKey    the unique key of the experiment.
   * @param user             the user to participate in the experiment. MUST NOT be null. (e.g. { id: "userId"} )
   * @param defaultVariation the default variation of the experiment. MUST NOT be null.
   *
   * @return {Decision} object
   */
  variationDetail(experimentKey: number, user?: User | string, defaultVariation?: string): Decision

  /**
   * Determine whether the feature is turned on to the user.
   *
   * @param featureKey the unique key for the feature.
   * @param user       the user requesting the feature.
   *
   * @return boolean True if the feature is on. False if the feature is off.
   *
   * @since 2.0.0
   */
  isFeatureOn(featureKey: number, user?: User | string): boolean

  /**
   * Determine whether the feature is turned on to the user, and returns an object that
   * describes the way the value was determined.
   *
   * @param featureKey the unique key for the feature.
   * @param user     the identifier of user.
   *
   * @return {FeatureFlagDecision}
   *
   * @since 2.0.0
   */
  featureFlagDetail(featureKey: number, user?: User | string): FeatureFlagDecision

  /**
   * Records the event performed by the user.
   *
   * @param event the unique key of the event. MUST NOT be null.
   * @param user the identifier of user that performed the event. id MUST NOT be null. (e.g. { id: "userId"} )
   */
  track(event: HackleEvent | string, user?: User | string): void

  trackPageView(option?: PageView): void

  /**
   * Return a instance of Hackle Remote Config.
   *
   * @param user     the identifier of user.
   */
  remoteConfig(user?: User | string): HackleRemoteConfig

  showUserExplorer(): void

  hideUserExplorer(): void

  fetch(): Promise<void>
}

export default class HackleClientImpl implements BrowserHackleClient {
  private readonly initialization: Promise<void>
  private initialized: boolean = false

  constructor(
    private readonly core: HackleCore,
    private readonly synchronizer: PollingSynchronizer,
    private readonly sessionManager: SessionManager,
    private readonly userManager: UserManager,
    private readonly fetchThrottler: Throttler,
    private readonly pageEventTracker: PageEventTracker,
    private readonly devTools?: DevTools
  ) {
    // This is to prevent the "This Binding" problem.
    this.initialize = this.initialize.bind(this)
    this.getSessionId = this.getSessionId.bind(this)
    this.getUser = this.getUser.bind(this)
    this.setUser = this.setUser.bind(this)
    this.setUserId = this.setUserId.bind(this)
    this.setDeviceId = this.setDeviceId.bind(this)
    this.setUserProperty = this.setUserProperty.bind(this)
    this.setUserProperties = this.setUserProperties.bind(this)
    this.updateUserProperties = this.updateUserProperties.bind(this)
    this.resetUser = this.resetUser.bind(this)
    this.variation = this.variation.bind(this)
    this.variationDetail = this.variationDetail.bind(this)
    this.isFeatureOn = this.isFeatureOn.bind(this)
    this.featureFlagDetail = this.featureFlagDetail.bind(this)
    this.track = this.track.bind(this)
    this.trackPageView = this.trackPageView.bind(this)
    this.remoteConfig = this.remoteConfig.bind(this)
    this.onReady = this.onReady.bind(this)
    this.onInitialized = this.onInitialized.bind(this)
    this.showUserExplorer = this.showUserExplorer.bind(this)
    this.hideUserExplorer = this.hideUserExplorer.bind(this)
    this.fetch = this.fetch.bind(this)
    this.close = this.close.bind(this)

    this.initialization = this.initialize()
  }

  private async initialize(): Promise<void> {
    try {
      log.debug("HackleClient initializing")
      await this.synchronizer.sync()
      log.debug(`HackleClient initialized`)
    } catch (e) {
      log.error(`HackleClient initialize failed: ${e}`)
      throw e
    } finally {
      this.initialized = true
    }
  }

  getSessionId(): string {
    return this.sessionManager.sessionId
  }

  getUser(): User {
    return this.userManager.currentUser
  }

  setUser(user: User): Promise<void> {
    try {
      const sanitizedUser = sanitizeUser(user)
      if (!sanitizedUser) {
        log.warn("invalid user")
        return Promise.resolve()
      }
      const updated = this.userManager.setUser(sanitizedUser)
      return this.userManager.syncIfNeeded(updated).catch(() => undefined)
    } catch (e) {
      log.error(`Unexpected exception while set user: ${e}`)
      return Promise.resolve()
    }
  }

  setUserId(userId: string | number | undefined): Promise<void> {
    try {
      let updated: Updated<User>
      if (userId === undefined) {
        updated = this.userManager.setUserId(undefined)
      } else {
        const sanitizedUserId = IdentifierUtil.sanitizeValue(userId)
        if (!sanitizedUserId) {
          log.warn(`Invalid userId. [userId=${userId}]`)
          return Promise.resolve()
        }
        updated = this.userManager.setUserId(sanitizedUserId)
      }
      return this.userManager.syncIfNeeded(updated).catch(() => undefined)
    } catch (e) {
      log.error(`Unexpected exception while set userId: ${e}`)
      return Promise.resolve()
    }
  }

  setDeviceId(deviceId: string): Promise<void> {
    try {
      const sanitizedDeviceId = IdentifierUtil.sanitizeValue(deviceId)
      if (!sanitizedDeviceId) {
        log.warn(`Invalid deviceId. [deviceId=${deviceId}]`)
        return Promise.resolve()
      }
      const updated = this.userManager.setDeviceId(sanitizedDeviceId)
      return this.userManager.syncIfNeeded(updated).catch(() => undefined)
    } catch (e) {
      log.error(`Unexpected exception while set deviceId: ${e}`)
      return Promise.resolve()
    }
  }

  setUserProperty(key: string, value: any): Promise<void> {
    const operations = new PropertyOperationsBuilder().set(key, value).build()
    return this.updateUserProperties(operations)
  }

  setUserProperties(properties: Properties): Promise<void> {
    try {
      const sanitizedProperties = PropertyUtil.sanitize(properties)
      const operations = new PropertyOperationsBuilder()

      Object.entries(sanitizedProperties).forEach(([key, value]) => {
        operations.set(key, value)
      })
      return this.updateUserProperties(operations.build())
    } catch (e) {
      log.error(`Unexpected exception while set userProperties: ${e}`)
      return Promise.resolve()
    }
  }

  updateUserProperties(operations: PropertyOperations): Promise<void> {
    try {
      const event = operations.toEvent()
      this.track(event)
      this.userManager.updateUserProperties(operations)
      this.core.flush(false)
      return Promise.resolve()
    } catch (e) {
      log.error(`Unexpected exception while update userProperties: ${e}`)
      return Promise.resolve()
    }
  }

  resetUser(): Promise<void> {
    try {
      const updated = this.userManager.resetUser()
      const clearAllOperations = new PropertyOperationsBuilder().clearAll().build()
      this.track(clearAllOperations.toEvent())
      return this.userManager.syncIfNeeded(updated).catch(() => undefined)
    } catch (e) {
      log.error(`Unexpected exception while reset user: ${e}`)
      return Promise.resolve()
    }
  }

  variation(experimentKey: number, user?: User | string, defaultVariation?: VariationKey): string {
    return this.variationDetail(experimentKey, user, defaultVariation).variation
  }

  variationDetail(experimentKey: number, user?: User | string, defaultVariation?: VariationKey): Decision {
    const defaultVariationKey = defaultVariation || "A"
    const sample = TimerSample.start()
    const decision = (function (_this: HackleClientImpl) {
      try {
        const hackleUser = _this.userManager.resolve(user)
        return _this.core.experiment(experimentKey, hackleUser, defaultVariationKey)
      } catch (e) {
        log.error(
          `Unexpected exception while deciding variation for experiment[${experimentKey}]. Returning default variation[${defaultVariationKey}] : ${e}`
        )
        return Decision.of(defaultVariationKey, DecisionReason.EXCEPTION)
      }
    })(this)

    DecisionMetrics.experiment(sample, experimentKey, decision)
    return decision
  }

  isFeatureOn(featureKey: number, user?: User | string): boolean {
    return this.featureFlagDetail(featureKey, user).isOn
  }

  featureFlagDetail(featureKey: number, user?: User | string): FeatureFlagDecision {
    const sample = TimerSample.start()
    const decision = (function (_this: HackleClientImpl) {
      try {
        const hackleUser = _this.userManager.resolve(user)
        return _this.core.featureFlag(featureKey, hackleUser)
      } catch (e) {
        log.error(
          `Unexpected exception while deciding feature flag[${featureKey}]. Returning default value[false] : ${e}`
        )
        return FeatureFlagDecision.off(DecisionReason.EXCEPTION)
      }
    })(this)

    DecisionMetrics.featureFlag(sample, featureKey, decision)
    return decision
  }

  track(event: HackleEvent | string, user?: User | string) {
    try {
      log.debug(`track event : ${JSON.stringify(event)}`)
      const hackleEvent = this._convertEvent(event)
      const hackleUser = this.userManager.resolve(user)
      this.core.track(hackleEvent, hackleUser)
    } catch (e) {
      log.error(`Unexpected exception while tracking event: ${e}`)
    }
  }

  trackPageView(option?: PageView): void {
    const page = Page.createWithCurrentPage()
    this.pageEventTracker.track(page)
  }

  remoteConfig(user?: User): HackleRemoteConfig {
    return new HackleRemoteConfigImpl(this.core, this.userManager, user)
  }

  onReady(block: () => void, timeout: number = DEFAULT_ON_READY_TIMEOUT): void {
    this.onInitialized({ timeout }).finally(() => block())
  }

  async onInitialized(config?: { timeout?: number }): Promise<{ success: boolean }> {
    if (this.initialized) {
      return { success: true }
    }

    try {
      await Promises.timeout(this.initialization, config?.timeout ?? DEFAULT_ON_READY_TIMEOUT)
      return { success: true }
    } catch (e) {
      log.error(`Failed to onInitialized: ${e}`)
      return { success: false }
    }
  }

  private _convertEvent(event: HackleEvent | string): HackleEvent {
    if (typeof event === "string") {
      return { key: event }
    }
    return event
  }

  showUserExplorer() {
    if (!this.devTools?.userExplorer) {
      log.error("UserExplorer is not provided")
      return
    }

    log.debug("UserExplorer opened.")
    Metrics.counter("user.explorer.show", {}).increment()
    this.devTools.manager.userExplorer(this.devTools.userExplorer)
  }

  hideUserExplorer() {
    if (!this.devTools) {
      log.warn("There is no active HackleDevtools.")
      return
    }

    this.devTools.manager.close()
  }

  fetch(): Promise<void> {
    return this.fetchThrottler.execute(
      () => this.synchronizer.sync(),
      () => Promise.resolve()
    )
  }

  close(): void {
    log.debug("HackleClient closing")
    this.core.close()
    this.synchronizer.close()
  }
}
