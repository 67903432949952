import { SplitUrlDeterminer } from "./SplitUrlDeterminer"
import { SplitUrlRedirector } from "./SplitUrlRedirector"
import HackleCore from "../../core/HackleCore"
import { PageListener } from "../../core/internal/page/PageListener"
import { Page } from "../../core/internal/page/Page"
import { UserManager } from "../../core/internal/user/UserManager"

export class SplitUrlManager implements PageListener {
  constructor(
    private readonly determiner: SplitUrlDeterminer,
    private readonly redirector: SplitUrlRedirector,
    private readonly userManager: UserManager,
    private readonly core: HackleCore
  ) {}

  onPageStarted(_: Page, __: number): void {
    const user = this.userManager.resolve()
    const context = this.determiner.determineOrNull(user)

    if (!context) return
    this.core.flush(true)
    this.redirector.redirect(context)
  }

  onPageEnded(_: Page, ___: number): void {}
}
